export const translationsLanguageCodes: string[] = [
  'ca',
  'es',
  'en',
];

export const translationsLanguageNativeNames: { [key: string]: string } = {
  'ca': 'Català',
  'es': 'Español',
  'en': 'English',
}
