import { IonPage } from '@ionic/react';
import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';

import Header from '../components/Header';
import Content from '../components/Content';
import Spinner from '../components/Spinner';

const AboutPage: React.FC = () => {

  const { t } = useTranslation();

  return (
    <IonPage className="page-about">
      <Header subtitle={t('about.title')}/>
      <Content t={t}>
        <div className="content-text">
          <figure className="mugshot"><img src="/assets/images/max-oriola-mugshot.jpg" width="374" height="527" alt=""/></figure>
          <h1>{t('about.title')}</h1>
          <ReactMarkdown source={t('about.content')}/>
        </div>
      </Content>
    </IonPage>
  );
};

const AboutPageSuspense: React.FC = () => {
  return (
    <Suspense fallback={<Spinner/>}>
      <AboutPage/>
    </Suspense>
  )
}

export default AboutPageSuspense;
