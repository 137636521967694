import React from 'react';
import { IonContent, IonIcon } from '@ionic/react';
import { call, mail } from 'ionicons/icons';

interface ContentProps {
  children: React.ReactNode;
  t: any, // must be the one from react-i18next
}

const Content: React.FC<ContentProps> = ({ children, t }) => {

  return (
    <IonContent class="component-content">
      {children}
      <div className="content-text">
        <h1>{t('contact.title')}</h1>
        <p>Max Oriola</p>
        <p><a href="tel:+34678739479"><IonIcon icon={call}/> +34 678 739 479</a></p>
        <p><a href="mailto:max@oriola.net"><IonIcon icon={mail}/> max@oriola.net</a></p>
      </div>
    </IonContent>
  );

}

export default Content;
