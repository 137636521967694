import { IonPage, IonContent, IonIcon } from '@ionic/react';
import { sadOutline } from 'ionicons/icons';
import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';

import Header from '../components/Header';
import Spinner from '../components/Spinner';

const NotFoundPage: React.FC = () => {

  const { t } = useTranslation();

  return (
    <IonPage className="page-about">
      <Header subtitle={t('not-found.title')}/>
      <IonContent class="component-content">
        <div className="content-text">
          <h1><IonIcon icon={sadOutline}/> {t('not-found.title')}</h1>
          <p><ReactMarkdown source={t('not-found.content')}/></p>
        </div>
      </IonContent>
    </IonPage>
  );
};

const NotFoundPageSuspense: React.FC = () => {
  return (
    <Suspense fallback={<Spinner/>}>
      <NotFoundPage/>
    </Suspense>
  )
}

export default NotFoundPageSuspense;
