
/**
 * These are a list of keys to experiences
 * these keys are used as translation keys
 */
export const experienceItems: string[] = [
  'self-employed',
  'ente-publicidad',
  'other',
];

/**
 * These are a list of keys to collaborators
 * in my self employed period
 */
export const experienceCollaborators: string[] = [
  'broccoli',
  'como',
  'mt-service',
  'ente-estudio',
];
