import { IonPage, IonList, IonItem, IonLabel, IonNote } from '@ionic/react';
import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';

import Header from '../components/Header';
import Content from '../components/Content';
import { languageInfos, LanguageInfo } from '../shared/languages';
import Spinner from '../components/Spinner';

const LanguagesPage: React.FC = () => {

  // const { name } = useParams<{ name: string; }>();

  const { t } = useTranslation();

  return (
    <IonPage className="page-languages">
      <Header subtitle={t('languages.title')}/>
      <Content t={t}>
        <div className="content-text">
          <h1>{t('languages.title')}</h1>
          <ReactMarkdown source={t('languages.content')}/>
        </div>
        <IonList class="languages-list">
          <IonItem class="item-header">
            <IonLabel>{t('languages.table-title')}</IonLabel>
            <IonNote slot="end">{t('languages.table-level')}</IonNote>
          </IonItem>
          {languageInfos.map((info: LanguageInfo) => (
            <IonItem class="body" key={info.name}>
              <IonLabel>{t('languages.names.'+info.name)}</IonLabel>
              <IonNote slot="end">{t('languages.levels.' + info.level)}</IonNote>
            </IonItem>
          ))}
        </IonList>
      </Content>
    </IonPage>
  );
};

const LanguagesPageSuspense: React.FC = () => {
  return (
    <Suspense fallback={<Spinner/>}>
      <LanguagesPage/>
    </Suspense>
  );
}

export default LanguagesPageSuspense;
