import React from 'react';

import AboutPage from '../pages/AboutPage';
import SkillsPage from '../pages/SkillsPage';
import ExperiencePage from '../pages/ExperiencePage';
import LanguagesPage from '../pages/LanguagesPage';

export interface AppPage {
  title: string;
  url: string;
  component: React.FunctionComponent<any>;
  localeKey: string;
}

export const appPages: AppPage[] = [
  {
    title: 'About',
    url: '/about',
    component: AboutPage,
    localeKey: 'about',
  },
  {
    title: 'Skills',
    url: '/skills',
    component: SkillsPage,
    localeKey: 'skills',
  },
  {
    title: 'Experience',
    url: '/experience',
    component: ExperiencePage,
    localeKey: 'experience',
  },
  {
    title: 'Languages',
    url: '/languages',
    component: LanguagesPage,
    localeKey: 'languages',
  },
];
