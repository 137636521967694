import { IonPage } from '@ionic/react';
import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import InlineMarkdown from '../components/InlineMarkdown';

import Header from '../components/Header';
import Content from '../components/Content';
import Spinner from '../components/Spinner';
import { skillsSections } from '../shared/skills';

const SkillsPage: React.FC = () => {

  const { t } = useTranslation();

  return (
    <IonPage>
      <Header subtitle={t('skills.title')}/>
      <Content t={t}>
        <div className="content-text">
          <h1>{t('skills.title')}</h1>
          {skillsSections.map((section: string) => (
            <React.Fragment key={section}>
              <h2><InlineMarkdown source={t('skills.sections.'+section+'.title')}/></h2>
              <ReactMarkdown source={t('skills.sections.'+section+'.content')}/>
            </React.Fragment>
          ))}
        </div>
      </Content>
    </IonPage>
  );
};

const SkillsPageSuspense: React.FC = () => {
  return (
    <Suspense fallback={<Spinner/>}>
      <SkillsPage/>
    </Suspense>
  )
}

export default SkillsPageSuspense;
