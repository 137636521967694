import React from 'react';
import ReactMarkdown from 'react-markdown';

interface ContentProps {
  source: string;
}

const InlineMarkdown: React.FC<ContentProps> = ({ source }) => {
  return <ReactMarkdown source={source}
    disallowedTypes={['paragraph']} unwrapDisallowed={true}/>
}

export default InlineMarkdown;
