import { IonContent, IonIcon, IonItem, IonLabel, IonList, IonMenu, IonMenuToggle } from '@ionic/react';
import { globeOutline } from 'ionicons/icons';

import React, { Suspense } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { translationsLanguageCodes, translationsLanguageNativeNames } from '../shared/translations';
import { AppPage, appPages } from '../shared/app-pages';

const MenuContent: React.FC = () => {

  const location: any = useLocation();
  const { t, i18n } = useTranslation();

  const setLng = (lng: string) => {
    i18n.changeLanguage(lng);
  }

  // setCurrentLng(i18n.language);

  return (
    <IonContent>
      <IonList id="inbox-list">
        {appPages.map((appPage: AppPage, index: number) => {
          return (
            <IonMenuToggle key={index} autoHide={false}>
              <IonItem className={location.pathname === appPage.url ? 'selected' : ''} routerLink={appPage.url} routerDirection="none" lines="none" detail={false}>
                <IonLabel>{t(appPage.localeKey+'.title')}</IonLabel>
              </IonItem>
            </IonMenuToggle>
          );
        })}
        <IonMenuToggle autoHide={false}>
          <IonItem routerDirection="none" lines="none" detail={false} className="ion-item-languages">
            <IonIcon className="languages-icon" icon={globeOutline}/>
            {translationsLanguageCodes.map((lng: string) => {
              const classes = [];
              classes.push('language');
              if(i18n.language === lng) {
                classes.push('selected');
              }
              return <button key={lng} className={classes.join(' ')} onClick={() => setLng(lng)}>{translationsLanguageNativeNames[lng]}</button>;
            })}
          </IonItem>
        </IonMenuToggle>
      </IonList>
    </IonContent>
  );
};

const Menu: React.FC = () => {
  return (
    <IonMenu contentId="main" className="component-menu" type="overlay" side="start">
      <Suspense fallback="">
        <MenuContent/>
      </Suspense>
    </IonMenu>
  );
};

export default Menu;
