/**
 * Language info (the ones I spoke, not the ones the app has)
 * Both `name` and `level` are keys used to translate from
 * `languages.names.{name}` and `languages.levels.{level}`.
 * `{name}` can be `catalan`, `spanish`, `english`, `japanese`.
 * `{level}` can be `high`, `basic`.
 */

export interface LanguageInfo {
  name: string;
  level: string;
}

export const languageInfos: LanguageInfo[] = [
  {
    name: 'catalan',
    level: 'native',
  },
  {
    name: 'spanish',
    level: 'native',
  },
  {
    name: 'english',
    level: 'high',
  },
  {
    name: 'japanese',
    level: 'basic',
  },
  {
    name: 'german',
    level: 'basic',
  },
];
