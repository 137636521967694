import React from 'react';
import { MoonLoader } from 'react-spinners';

import { colorCorporate } from '../shared/scss-variables';

const Spinner: React.FC = () => {
  return (
    <div className="component-spinner">
      <MoonLoader color={colorCorporate}/>
    </div>
  )
}

export default Spinner;
