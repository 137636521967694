import React from 'react';
import { IonHeader, IonToolbar, IonButtons, IonMenuButton, IonTitle } from '@ionic/react';

interface HeaderProps {
  subtitle?: string;
}

const Header: React.FC<HeaderProps> = ({ subtitle }: HeaderProps) => {

  return (
    <IonHeader class="component-header">
      <IonToolbar>
        <IonButtons slot="start">
          <IonMenuButton />
        </IonButtons>
        <IonTitle>Max Oriola CV <span className="subtitle">/ {subtitle}</span></IonTitle>
      </IonToolbar>
    </IonHeader>
  );

}

export default Header;
