import { IonPage, IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent } from '@ionic/react';
import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';

import Header from '../components/Header';
import Content from '../components/Content';
import Spinner from '../components/Spinner';
import { experienceItems } from '../shared/experience';
import { experienceCollaborators } from '../shared/experience';

const ExperiencePage: React.FC = () => {

  const { t } = useTranslation();

  return (
    <IonPage className="page-experience">
      <Header subtitle={t('experience.title')}/>
      <Content t={t}>
        <div className="content-text">
          <h1>{t('experience.title')}</h1>
          {experienceItems.map((itemKey: string) => (
            <div key={itemKey} className="item">
              <h2>{t('experience.items.'+itemKey+'.title1')}</h2>
              <p className="item-subtitle"> {t('experience.items.'+itemKey+
                '.title2')}</p>
              <ReactMarkdown source={t('experience.items.'+itemKey+
                '.content')}/>
              {itemKey === 'self-employed' ? (
                <div className="collaborators">
                  <h3>{t('experience.collaborators.title')}</h3>
                  {experienceCollaborators.map((collaboratorKey: string) => (
                      <div className="collaborator">
                        <h4>{t('experience.collaborators.items.'+collaboratorKey+
                          '.title')}</h4>
                        <ReactMarkdown source={t('experience.collaborators.items.'+
                          collaboratorKey+'.content')} />
                      </div>
                    ))}
                </div>
              ) : null}
            </div>
          ))}
        </div>
      </Content>
    </IonPage>
  );
};

const ExperiencePageSuspense: React.FC = () => {
  return (
    <Suspense fallback={<Spinner/>}>
      <ExperiencePage/>
    </Suspense>
  );
}

export default ExperiencePageSuspense;
